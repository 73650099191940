import React, { Component } from 'react';
import { Button, Checkbox, Row, Col, Form, Input, Select, message, } from 'antd'
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import AddEmployee from './addEmploye';
import App from '../../App'
import createInternalUser from '../../mutation/createInternalUser'
import verifyUsername from '../../mutation/verifyUsername'
import { externalPermissions, mainPermissions, userRole } from '../../utils/permissionsObj'
import { getStateByCountry, getcitiesByStateAndCountry } from "../../utils/getStateByCountry"
import getInternalUsers from '../../queries/getInternalUsers';
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const country = [
    { value: 'IND', label: 'India',key:'IN' },
    { value: 'AUS', label: 'Australia',key:'AU' },
    { value: 'USA', label: 'USA',key: 'US' },
    { value: 'UK', label: 'UK',key:'GB' },
    { value: 'UAE', label: 'UAE',key:'AE' },
    { value: 'ZMW', label: 'Zambia',key:'ZM' },
    { value: 'SAU', label: 'Saudi Arabia',key:'SA' },
    { value: 'QAT', label: 'Qatar',key:'QA' },
    { value: 'SGP', label: 'Singapore',key:'SG' },
    { value: 'NPL', label: 'Nepal',key:'NP' },
    { value: 'KWT', label: 'Kuwait',key:'KW' },
    { value: 'OMN', label: 'Oman',key:'OM' },
    { value: 'KEN', label: 'Kenya',key:'KE' }
  ]
class CreateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedParmision: [],
            userNameCheck: true,
            loading: false,
            role: undefined,
            selectedCountry: undefined,
            selectedState: undefined,
            selectedCity: undefined,
            allState: [],
            allCities: [],
            userData:null

        }
    }

    componentDidMount = () => {
        // console.log("create user Did Mount:::")
        this.getUsers()
    }

    getUsers=()=>{
        this.props.client.query({
            query: getInternalUsers,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
                this.setState({
                    userData: data.getInternalUsers
                })
            })
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    changeRole = (value) => {
        this.setState({
            role: value
        })
    }
    onCheckChange = (value) => {
        this.setState({
            selectedParmision: value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            //  console.log("values",values,this.state.selectedParmision)
         
            //  return
            if (!err) {
                this.setState({ loading: true })
                let finalPermissions = []

                if (values && values.permissions && values.permissions.length > 0) {
                    let entities = [], actions = []
                    values.permissions.map((permission, index) => {
                        if (values.permissions[index].includes("::")) {
                            actions.push(permission)
                        } else {
                            entities.push(permission)
                        }
                    })
                    const getopration = (actions, permission) => {
                        let op = []
                        if (actions && actions.length > 0) {
                            actions.map((action) => {
                                if (action.startsWith(`${permission}::`)) {
                                    op.push(action.split(`${permission}::`)[1])
                                }
                            })
                            return op
                        }
                    }
                    finalPermissions = entities.map((permission, index) => {
                        // if(!(permission.includes("::"))){
                        if (!(permission.startsWith(`${permission}::`))) {
                            return (
                                {
                                    name: entities[index],
                                    // operations:  actions && actions.length > 0 ? actions.map((action) => action.split(`${permission}::`)[1]) : undefined
                                    operations: actions && getopration(actions, permission)
                                }
                            )
                        }
                    })
                  
                }
                // this.setState({loading: false})
                // return
                this.props.client.mutate({
                    mutation: createInternalUser,
                    variables: {
                        siteId: 'master',
                        userName: values.emailId,
                        password: "Temp@123",
                        role: values.role,
                        emailId: values.emailId,
                        mobile: values.mobile,
                        name: values.name,
                        companyName: values.companyName,
                        gstNo: values.gstNo,
                        panNo: values.panNo,
                        onboardedBy: values.onboardedBy,
                        supportNumber: values.supportNumber,
                        enabled: true,
                        permissions: finalPermissions,
                        empsDetails: values.employee ? values.employee : undefined,
                        address: {
                            addressLineOne: values.addressLineOne ? values.addressLineOne : undefined,
                            addressLineTwo: values.addressLineTwo ? values.addressLineTwo : undefined,
                            city: values.city ? values.city : undefined,
                            state: values.state ? values.state : undefined,
                            zip: values.zip ? values.zip : undefined,
                            country: values.country ? values.country : undefined
                        }

                    }
                }).then(({ data }) => {
                    console.log("Resp SiteConfig ::", data)
                    message.success('User Created Successfully')
                    this.props.history.push('/manage-user')
                })
            } else {
                this.setState({ loading: false })
            }
        })
    }
    handleFocus = e => {
        this.setState({ showError: true });
    };
    userNameChange = (e) => {
        let value = e.target.value
        this.setState({
            username: value
        })
        if (value.length >= 6) {
            this.checkUserName(value)
        } else {
            this.setState({
                userNameCheck: true
            })
        }
    }
    checkUserName = (value) => {
        this.props.client.mutate({
            mutation: verifyUsername,
            variables: {
                username: value
            }
        }).then(({ data }) => {
            // console.log("Response ::",data)
            this.setState({
                userNameCheck: data.verifyUsername
            })
        }).catch((err) => {
            this.setState({
                userNameCheck: true
            })
        })
    }
    onCountryChange = async (value) => {
        let index = country.findIndex((x) => x.value == value)
        let response = await getStateByCountry(country[index].key)
        console.log("response ::", response)
        if (response) {
            this.setState({
                selectedCountry: value,
                allState: response
            })
        }
    }
    onStateChange = async (value) => {
        let cIndex = country.findIndex((x) => x.value == this.state.selectedCountry)
        let sIndex = this.state.allState.findIndex((x) => x.name === value)
        let cities = await getcitiesByStateAndCountry(country[cIndex].key, this.state.allState[sIndex].iso2)
        this.setState({
            selectedState: value,
            allCities: cities
        })
    }
    onCityChange = (value) => {
        this.setState({
            selectedCity: value
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        // console.log("externalPermissions ::",externalPermissions)
       
        return (
            <App header={'Create User'}>
                <Form onSubmit={this.handleSubmit} >
                    <Row gutter={16}>
                        
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Name' >
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Name.",
                                    }],
                                })(

                                    <Input placeholder="Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Contact Number' >
                                {getFieldDecorator('mobile', {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Contact Number.",
                                    }],
                                })(

                                    <Input placeholder="Contact Number" type='number' style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Email'
                                hasFeedback
                                help={this.state.showError && this.state.userNameCheck ? <span style={{ color: 'red' }}>This username is not available. Please try another one</span> : ""}
                            >
                                {getFieldDecorator('emailId', {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Email.",
                                    }, {

                                        validator: (rule, value, cb) => {
                                            if (value) {
                                                if (!value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/)) {
                                                    cb('Please enter valid email address')
                                                }
                                            }
                                            cb()
                                        }
                                    }],
                                })(

                                    <Input placeholder="Email" style={{ width: '100%' }} autoComplete="new-password" onFocus={this.handleFocus} onChange={this.userNameChange} id={this.state.userNameCheck ? "warning" : "success"} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Role' >
                                {getFieldDecorator('role', {
                                    rules: [{
                                        required: true,
                                        message: "Please select Role",
                                    }],
                                })(
                                    <Select showSearch style={{ width: '100%', color: "black" }} onChange={this.changeRole} placeholder="Select User Role">
                                        {userRole && userRole.length > 0 ? userRole.map((ele) => {
                                            return (
                                                <Option key={ele.value} value={ele.value}>{ele.label}</Option>
                                            )
                                        }) : ''}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        {(this.state.role && this.state.role == 'CP' || this.state.role == 'RESELLER' || this.state.role == 'REFERRAL' || this.state.role == 'STOCKIST') &&
                            <>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Company Name' className="booking-label">
                                    {getFieldDecorator('companyName', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter company Name",
                                        }],
                                    })(
                                        <Input placeholder="Company Name" />
                                    )}
                                </FormItem>
                            </Col>

                                {this.state.role && this.state.role == 'CP' && 
                                
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Onboarded By' >
                                    {getFieldDecorator('onboardedBy', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter sales person name.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Sales Person Name">
                                                {this.state.userData && this.state.userData.length > 0 &&
                                                this.state.userData.map((ele)=>{
                                                    if(ele.userRole == 'SALES_PERSON'){
                                                    return(
                                                        <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                    )
                                                }
                                                })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                               }

                               
                              {this.state.role && this.state.role == 'CP' && <> 
                              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Customer Support Number' className="booking-label">
                                        {getFieldDecorator('supportNumber', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter Customer Support Number",
                                            }],
                                        })(
                                            <Input placeholder="Customer Support Number" />
                                        )}
                                    </FormItem>
                                </Col> 
                              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='GST Number' className="booking-label">
                                        {getFieldDecorator('gstNo', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter GST Number",
                                            }],
                                        })(
                                            <Input placeholder="GST Number" />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='PAN Number' className="booking-label">
                                        {getFieldDecorator('panNo', {
                                            rules: [{
                                                required: false,
                                                message: "Please Enter Pan Number",
                                            }],
                                        })(
                                            <Input placeholder="PAN Number" />
                                        )}
                                    </FormItem>
                                </Col> </> }

                            
                              
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Country' className="booking-label">
                                        {getFieldDecorator('country', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter country",
                                            }],
                                        })(

                                            <Select onChange={this.onCountryChange}>
                                                {country.map((c) => {
                                                    return (
                                                        <Option key={c.value} value={c.value} >{c.label}</Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='State' className="booking-label" >
                                        {getFieldDecorator('state', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter state",
                                            }],
                                        })(

                                            <Select
                                                optionFilterProp="children"
                                                style={{ width: '100%', marginRight: '10px' }}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                placeholder="State"
                                                value={this.state.selectedState}
                                                showSearch
                                                allowClear
                                                onChange={this.onStateChange}>
                                                {this.state.allState && this.state.allState.length > 0 && this.state.allState.map((c) => {
                                                    return (
                                                        <Option key={c.iso2} value={c.name} >{c.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='City' className="booking-label">
                                        {getFieldDecorator('city', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter city.",
                                            }],
                                        })(

                                            <Select
                                                optionFilterProp="children"
                                                style={{ width: '100%', marginRight: '10px' }}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                placeholder="City"
                                                value={this.state.selectedCity}
                                                showSearch
                                                onChange={this.onCityChange}>
                                                {this.state.allCities && this.state.allCities.length > 0 && this.state.allCities.map((c) => {
                                                    return (
                                                        <Option key={c.id} value={c.name} >{c.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Zip' className="booking-label">
                                        {getFieldDecorator('zip', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter zip.",
                                            }],
                                        })(
                                            <Input placeholder="Zip" />
                                        )}
                                    </FormItem>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Address Line 1' className="booking-label">
                                        {getFieldDecorator('addressLineOne', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter Address Line 1",
                                            }],
                                        })(

                                            <TextArea rows={2} placeholder="Address Line 1" />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Address Line 2' className="booking-label">
                                        {getFieldDecorator('addressLineTwo', {
                                            rules: [{
                                                required: false,
                                                message: "Please enter Address Line 2",
                                            }],
                                        })(

                                            <TextArea rows={2} placeholder="Address Line 2" />
                                        )}
                                    </FormItem>
                                </Col>


                            </>
                        }
                    </Row>
                    <Row>
                    { this.state.role && this.state.role == 'CP' && <AddEmployee form={this.props.form} />}
                    </Row>
                    {/* <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Password' >
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Password.",
                                    }, {
                                        validator: this.validateToNextPassword,
                                      }],
                                })(

                                    <Input.Password  autoComplete="new-password" placeholder="Password" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Confirm Password' >
                                {getFieldDecorator('passwordC', {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Confirm Password.",
                                    }, {
                                        validator: this.compareToFirstPassword,
                                      }],
                                })(

                                    <Input.Password placeholder="Confirm Password" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Role' >
                                {getFieldDecorator('role', {
                                    rules: [{
                                        required: true,
                                        message: "Please select Role",
                                    }],
                                })(
                                        <Select showSearch style={{width:'100%', color: "black"}} onChange={this.changeRole} placeholder="Select User Role">
                                             {userRole&& userRole.length > 0 ? userRole.map((ele)=>{
                                                return(
                                                    <Option key={ele.value} value={ele.value}>{ele.label}</Option>
                                                )
                                             }):''}
                                        </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row> */}



                      

                    <Row gutter={16}>

                        {this.state.role && this.state.role != 'CP' && this.state.role != 'RESELLER' && this.state.role != 'REFERRAL' && this.state.role != 'STOCKIST' && this.state.role != 'SALES_PERSON' &&

                            <FormItem label='User Permissions' >
                                {getFieldDecorator('permissions', {
                                    rules: [{
                                        required: true,
                                        message: "Please select User Permissions",
                                    }],
                                })(
                                    <Checkbox.Group onChange={this.onCheckChange}>
                                        {mainPermissions.map((menu, i) => {
                                            return (
                                                <>
                                                    <Row style={{ padding: "6px 0" }}>
                                                        <Checkbox value={menu.value} key={i}>{menu.label}</Checkbox>
                                                    </Row>
                                                    {this.state.selectedParmision && this.state.selectedParmision.length > 0 && this.state.selectedParmision.includes(menu.value) &&
                                                        <Row>
                                                            {externalPermissions[menu.value].map((action) => {
                                                                return (
                                                                    <span style={{ padding: "10px 20px" }}>
                                                                        <Checkbox value={`${menu.value}::${action.value}`}>{action.label}</Checkbox>
                                                                    </span>
                                                                )
                                                            })
                                                            }
                                                        </Row>
                                                    }
                                                </>
                                            )
                                        })}
                                    </Checkbox.Group>
                                )}
                            </FormItem>

                        }

                    </Row>

                    <Row>
                        <Link to={{ pathname: "/manage-user" }}>
                            <Button type="default" style={{ background: "#f44336", color: "#fff", marginLeft: "20px", float: "right", }}>CANCEL</Button>
                        </Link>
                        <Button loading={this.state.loading} disabled={this.state.loading} id="submit" type="primary" htmlType="submit" style={{ float: "right", cursor: "pointer", color: "#fff", marginLeft: '10px' }}>SUBMIT</Button>
                    </Row>
                </Form>
            </App>
        );
    }
}
const WrapCreateUser = Form.create()(CreateUser);
export default withApollo(WrapCreateUser);