import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Form, Row,Col,Button,Input,Select,message,  } from 'antd';

import App from '../../App'
import createGroupAdmin from '../../mutation/createGroupAdmin'
import getSitesAdminForOutlets from '../../queries/getAdminForOutlets'
import verifyUsername from '../../mutation/verifyUsername'


const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

const country = [
    { value: 'IND', label: 'India' },
    { value: 'AUS', label: 'Australia'},
    { value: 'USA', label: 'USA' },
    { value: 'UK', label: 'UK' },
    { value: 'UAE', label: 'UAE' },
    { value: 'QAT', label: 'Qatar',key:'QA' },
    { value: 'BHR', label: 'Bahrain',key:'BH' },
    { value: 'SGP', label: 'Singapore',key:'SG' },
    { value: 'NPL', label: 'Nepal',key:'NP' },
    { value: 'KWT', label: 'Kuwait',key:'KW' },
    { value: 'OMN', label: 'Oman',key:'OM' },
    { value: 'KEN', label: 'Kenya',key:'KE' }
]
class CreateOulets extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          siteData: [],
          defaultSite: null,
          showError: false,
          userNameCheck: true,
        };
      }
      componentDidMount(){
        this.props.client.query({
            query: getSitesAdminForOutlets,
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('dataa', data)
              this.setState({
                siteData: data.getSitesAdmin,
                loading: false
              })
            })
      }
      userNameChange=(e)=>{
        let value = e.target.value
            this.setState({
                username:value
            })
            if(value.length >= 6){
                this.checkUserName(value)
            }else{
                this.setState({
                    userNameCheck: true
                })
            }
    }
      checkUserName=(value)=>{
        // console.log("client",this.props.client)
        this.props.client.mutate({
            mutation: verifyUsername,
            variables: {
                username: value
            }
        }).then(({ data }) => {
            this.setState({
                userNameCheck: data.verifyUsername
            })
        })
    }
    handleFocus = e => {
        this.setState({ showError: true });
      };
      onChnagesite1=(siteId)=>{
        let all_sites = this.state.siteData
        let siteObj = all_sites.find((x)=> x.siteId == siteId)
        let obj={
            "siteId": siteId,
            "typeValue": `fqdn::${siteObj.fqdn}`
        }
        this.setState({
            defaultSite: obj
        })
      }
      onChnagesite=()=>{

      }
      enterLoading=()=>{
        this.setState({
            loading: true
        })
      }
      handleSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields(async(err, values) => {
            if(!err){
                this.enterLoading()
                // console.log("values ::",values,this.state.defaultSite)
                let all_sites = this.state.siteData
                let outlets=[]
                outlets.push(this.state.defaultSite)
                for(let i=0; i<values.site.length;i++){
                    let siteObj = all_sites.find((x)=> x.siteId == values.site[i])
                    let obj={
                        "siteId": values.site[i],
                        "typeValue": `fqdn::${siteObj.fqdn}`
                    }
                    outlets.push(obj)
                }
                console.log("outlets ::",outlets)
                // return
                this.props.client.mutate({
                    mutation: createGroupAdmin,
                    variables: {
                        email: values.email,
                        mobile: values.mobile,
                        userName: values.userName.trim(),
                        custName: values.custName,
                        companyName: values.companyName,
                        enabled: true,
                        address: {
                            addressLineOne: values.addressLineOne ? values.addressLineOne : undefined,
                            addressLineTwo: values.addressLineTwo ? values.addressLineTwo : undefined,
                            city: values.city ? values.city : undefined,
                            state: values.state ? values.state : undefined,
                            zip: values.zip ? values.zip : undefined,
                            country: values.country ? values.country : undefined
                        },
                        outlets: outlets,
                    }
                }).then(({ data }) => {
                    console.log("data response",data)
                    if(data.createGroupAdmin){
                        message.success("Outlet Created Successfully");
                        this.setState({
                            loading: false
                        })
                    }
                        
                    })
            }
        })
      }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <App header={"Create Oulets"}>
            <Form onSubmit={this.handleSubmit} style={{marginTop: '10px'}}>
            <Row gutter={16}>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Company Name"
                            >
                                {getFieldDecorator(`companyName`, {
                                  
                                    rules: [{
                                        required: true,
                                        message: "Please enter Company Name",
                                    }]
                                })(
                                    <Input placeholder="Company Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Customer Name"
                            >
                                {getFieldDecorator(`custName`, {
                                  
                                    rules: [{
                                        required: true,
                                        message: "Please enter Customer Name",
                                    }]
                                })(
                                    <Input placeholder="Customer Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mobile`, {
                                  
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    }]
                                })(
                                    <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row> 
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem label='Email' >
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            required: true,
                                            message: "Please enter email.",
                                        }, {
                                            validator: (rule, value, cb) => {
                                                if (value) {
                                                    if (!value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                                        cb('Please enter valid email address')
                                                    }
                                                }
                                                cb()
                                            }
                                        },
                                        ],
                                    })(
                                        <Input placeholder="Email" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
            <Row gutter={16}>
            <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="User Name"
                                hasFeedback 
                                help={this.state.showError && this.state.userNameCheck  ? <span style={{color:'red'}}>This username is not available. Please try another one</span> : ""}
                                validateStatus={this.state.userNameCheck ? "warning" : "success"}
                            >
                                {getFieldDecorator(`userName`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter User Name",
                                    }]
                                })(
                                    <Input placeholder="Enter Username" onFocus={this.handleFocus} onChange={this.userNameChange}  id={this.state.userNameCheck ? "warning" : "success"}  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
            <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem
                                label="Select Default Site"
                            >
                                {getFieldDecorator(`site1`, {
                                  
                                    rules: [{
                                        required: true,
                                        message: "Select Default Site",
                                    }]
                                })(
                                    <Select
                                        showSearch
                                        placeholder="Select Default Site"
                                        onChange={this.onChnagesite1}
                                    >
                                      {this.state.siteData && this.state.siteData.length > 0 ? this.state.siteData.map((ele)=>{
                                        return(
                                            <Option value={ele.siteId}>{ele.fqdn}</Option>
                                        )
                                      }): ''}
                                    </Select>
                                )}
                            </FormItem>
            </Col>
            <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
            <FormItem
                                label="Select Oulets Site"
                            >
                                {getFieldDecorator(`site`, {
                                  
                                    rules: [{
                                        required: true,
                                        message: "Select Oulets Site",
                                    }]
                                })(
                                    <Select
                                        showSearch
                                        placeholder="Select Oulets Site"
                                        onChange={this.onChnagesite}
                                        mode="tags"
                                    >
                                       {this.state.siteData && this.state.siteData.length > 0 ? this.state.siteData.map((ele)=>{
                                        return(
                                            <Option value={ele.siteId}>{ele.fqdn}</Option>
                                        )
                                      }): ''}
                                    </Select>
                                )}
                            </FormItem>
            </Col>
            </Row>
            <Row gutter={16}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                <FormItem label='Address Line 1'>
                                    {getFieldDecorator('addressLineOne', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 1",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 1" style={{ width: '100%' }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Address Line 2'>
                                    {getFieldDecorator('addressLineTwo', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 2",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 2" style={{ width: '100%' }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='City'>
                                    {getFieldDecorator('city', {
                                        rules: [{
                                            required: true,
                                            message: "Please enter city.",
                                        }],
                                    })(

                                        <Input placeholder="City" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='State' >
                                    {getFieldDecorator('state', {
                                        rules: [{
                                            required: true,
                                            message: "Please enter state",
                                        }],
                                    })(

                                        <Input placeholder="State" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Zip'>
                                    {getFieldDecorator('zip', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter zip.",
                                        }],
                                    })(
                                        <Input placeholder="Zip" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Country'>
                                    {getFieldDecorator('country', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(

                                        <Select onChange={this.onCountryChange}>
                                            {country.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value} >{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Button id="submit" type="primary" loading={this.state.loading} htmlType="submit" style={{ float: "left", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                            Submit
                        </Button>
            </Form>
            </App>
        );
    }
}
const WrappedCreateOulets = Form.create({ name: "CreateOulets" })(
    CreateOulets
  );
export default withApollo(WrappedCreateOulets);